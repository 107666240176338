body {
  margin: 0;
}
.slider-left {
  display: none !important;
}

.slider-right {
  display: none !important;
}
